@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: MuseoSans100;
    src: url("./assets/fonts/MuseoSans-100.otf") format("opentype");
}

@font-face {
    font-family: MuseoSans300;
    src: url("./assets/fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
    font-family: MuseoSans500;
    src: url("./assets/fonts/MuseoSans_500.otf") format("opentype");
}

@font-face {
    font-family: MuseoSans700;
    src: url("./assets/fonts/MuseoSans_700.otf") format("opentype");
}

* {
    font-family: 'Roboto', sans-serif;
}

.css-1e2bxag-MuiDataGrid-root {
    z-index: 0 !important;
}

.bg-hero-pattern {
    background-image: url('./assets/images/pallete.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.museosans100 {
    font-family: 'MuseoSans100';
}

.museosans300 {
    font-family: 'MuseoSans300';
}

.museosans500 {
    font-family: 'MuseoSans500';
}

.museosans700 {
    font-family: 'MuseoSans700';
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.color-dark {
    color: #495057;
}

.color-fake {
    color: #000000DE
}

.footer-bg {
    background: rgba(29, 43, 77, 0.7);
}

.search {
    border: 'none' !important;
    color: #6b7280;
    background: #f5f5f5 !important;
    padding: 16.5px 14px;
    border-radius: 6px;
}
.search:hover {
    border: 'none' !important;
}
.search:focus-within {
    border: 'none' !important;
}
.search:focus {
    outline: none;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.icons {
    width: 37px;
    height: 37px;
}

#file-upload-button {
    display: none !important;
}


/* .css-79ws1d-MuiModal-root {
    z-index: 1600 !important;
} */

.css-1wf8b0h-MuiTabs-flexContainer {
    gap: 40px !important;
}
.MuiModal-root .css-8ndowl {
    z-index: 25000 !important;
}

.css-1wf8b0h-MuiTabs-flexContainer {
    gap: 40px !important;
}

.swal2-container {
    z-index: 50000 !important;
}

.whole {
    background-color: black;
    opacity: 0.5;
    z-index: 25000;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.css-11ghgbe-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: transparent !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: transparent !important;
}

.css-zs29tv-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before{
    border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.MuiTabs-flexContainer {
    justify-content: flex-start !important;
    gap: 40px !important;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    --tw-ring-color: transparent !important;
}

*, ::before, ::after {
    --tw-ring-color: transparent !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100000;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


  @keyframes arrow-down {
    0% {
        transform: translateX(0px) translateY(0px);
    }

    50% {
        opacity: 0;
        transform: translateX(-22px) translateY(22px);
    }

    51% {
        transform: translateX(22px) translateY(-22px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }

}

@keyframes arrow-up {
    0% {
        transform: translateX(0px) translateY(0px);
    }

    50% {
        opacity: 0;
        transform: translateX(22px) translateY(-22px);
    }

    51% {
        transform: translateX(-22px) translateY(22px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }

}

@keyframes arrow-left {
    0% {
        transform: translateX(0px) translateY(0px);
    }

    50% {
        opacity: 0;
        transform: translateX(-22px) translateY(0px);
    }

    51% {
        transform: translateX(22px) translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes arrow-right {
    0% {
        transform: translateX(0px) translateY(0px);
    }

    50% {
        opacity: 0;
        transform: translateX(22px) translateY(0px);
    }

    51% {
        transform: translateX(-22px) translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
}

.arrow-down {
    animation: arrow-down 1.5s infinite;
    animation-name: arrow-down;
}

.arrow-up {
    animation: arrow-up 1.5s infinite;
    animation-name: arrow-up;
}

.arrow-left {
    animation: arrow-left 1.5s infinite;
    animation-name: arrow-left;
}

.arrow-right {
    animation: arrow-right 1.5s infinite;
    animation-name: arrow-right;
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;
	font-weight: bold !important;
	text-align: center;
	border-radius: 15px;
	font-size: 12px;
    font-weight: bolder;
    color: #ffffff;
}

.marker-cluster span {
    line-height: 30px;
}

.shipments-marker-cluster {
    background-color: #336195;
}

.collections-marker-cluster {
    background-color: #b7472a;
}

.nulls-marker-cluster {
    background-color: #755460;
}

.pickup::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 7.5px;
    border-style: solid;
    border-color: rgb(211, 211, 30) transparent transparent rgb(211, 211, 30);
}

.tpl::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 7.5px;
    border-style: solid;
    border-color: rgb(76, 175, 80) transparent transparent rgb(76, 175, 80);
}

.brokered::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 7.5px;
    border-style: solid;
    border-color: rgb(255,25,25) transparent transparent rgb(255,25,25);
}

.place::placeholder {
    font-weight: 400
}

.padding-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#site_select {
    white-space: inherit !important;
    color: #718096 !important;
    font-size: 13px !important;
}
#input-with-icon-textfield,  #input-with-icon-textfieldtwo {
    padding-left: 10px !important;
}

#input-with-icon-textfieldtwo::placeholder {
    font-size: 50px;
    position: relative;
    top: 12px;
}

.MuiInputLabel-outlined {
    color: rgba(0, 0, 0, 0.6) !important;
}

.error {
    border-top: 10px solid #FF0000;
}

.success {
    border-top: 10px solid #00B78E;
}

.info {
    border-top: 10px solid #a8d5f1;
}

.warning2 {
    border-top: 10px solid #FEB806;
}

.swal2-icon {
    margin-bottom: 30px !important;
}

.error > .swal2-actions > .swal2-confirm {
    background-color: #FF0000;
    width: 120px;
    height: 40px;
    text-align: center;
    font-size: 14px;
}

.success > .swal2-actions > .swal2-confirm {
    background-color: #00B78E;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 14px;
}

.warning2 > .swal2-actions > .swal2-confirm {
    background-color: #FEB806;
    width: 200px;
    height: 40px;
    text-align: center;
    font-size: 14px;
}

.warning2 > .swal2-actions > .swal2-cancel, .warning2 > .swal2-actions > .swal2-deny {
    background-color: #F3F5F7;
    width: 200px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    color: #919399;
}

.swal2-close {
    color: #333333 !important;
}

.success > .swal2-actions > .swal2-confirm:hover{
    background-color: #008C6D;
}

.error > .swal2-actions > .swal2-confirm:hover{
    background-color: #E02121;
}

.warning2 > .swal2-actions > .swal2-confirm:hover{
    background-color: #DBA34B;
}

.warning2 > .swal2-actions > .swal2-cancel:hover, .warning2 > .swal2-actions > .swal2-deny{
    background-color: #E6E6E6;
}

.swal2-html-container {
    margin: 0 !important;
    padding: 10px 10px 20px 10px !important;
    color: #515151 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.swal2-title {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.address {
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #EEEFF2;
    background: #FCFCFC;
}

.edit-view {
    border-radius: 8px;
    border: 0.25px solid #E9E9E9;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.status {
    border-radius: 3px;
    border: 1px solid #EEEFF2;
    background: #FCFCFC;
    font-style: normal;
    font-weight: 400;
}

.MuiDataGrid-iconSeparator {
    display: none !important;
}

.MuiIconButton-root {
    background-color: transparent !important;
}

.text > .Mui-selected {
    color: #b7472a !important;
}

.MuiInputLabel-shrink {
    color: #336195 !important;
}

.table-pagination {
    color: #88909C !important;
}

::-ms-reveal {
    display: none;
}
