.broker .fc-timeGridDay-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.broker .fc-timeGridDay-button:hover{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-timeGridWeek-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.broker .fc-timeGridWeek-button:hover{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-dayGridMonth-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.broker .fc-dayGridMonth-button:hover{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-today-button {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
    color: #7B8FA1 !important;
    font-weight: bold !important;
}

.broker .fc-today-button:hover {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
}

.broker .fc-today-button:disabled {
    font-weight: normal !important;
}

.broker .fc-next-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.broker .fc-next-button:hover{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-prev-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.broker .fc-prev-button:hover{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-button-active{
    background-color: #148c8cb3 !important;
    border: #148c8cb3 !important;
}

.broker .fc-event,
.broker .fc-event-title {
    padding: 0 1px;
    white-space: normal;
}
