.dispatch .fc-timeGridDay-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.dispatch .fc-timeGridDay-button:hover{
    background-color: #755460 !important;
    border: #755460 !important;
}

.dispatch .fc-timeGridWeek-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.dispatch .fc-timeGridWeek-button:hover{
    background-color: #755460 !important;
    border: #755460 !important;
}

.dispatch .fc-dayGridMonth-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.dispatch .fc-dayGridMonth-button:hover{
    background-color: #755460 !important;
    border: #755460 !important;
}

.dispatch .fc-today-button {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
    color: #7B8FA1 !important;
    font-weight: bold !important;
}

.dispatch .fc-today-button:disabled {
    font-weight: normal !important;
}

.dispatch .fc-today-button:hover {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
}

.dispatch .fc-next-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.dispatch .fc-next-button:hover{
    background-color: #755460 !important;
    border: #755460 !important;
}

.dispatch .fc-prev-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.dispatch .fc-prev-button:hover{
    background-color: #755460 !important;
    border: #755460 !important;
}

.dispatch .fc-button-active{
    background-color: #755460 !important;
    border: #755460 !important;
}
