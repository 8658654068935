.shipment {
    background-color: #336195 !important;
}

.collection {
    background-color: #b7472a !important;
}

.confirm {
    background-color: rgb(3 105 161) !important;
}

.deny {
    background-color: rgb(156 163 175) !important;
}

.check-documents-popup {
    padding: 0 !important;
    width: 28em !important;
}

.check-documents-title {
    background-color: #df9133 !important;
    padding: 10px !important;
    color: white !important;
    text-align: left !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.25rem !important;
}

.check-documents-html-container {
    color: rgb(71 85 105) !important;
    padding: 20px 10px !important;
    text-align: left !important;
    margin: 0 !important;
    border-bottom: 1px solid rgb(209 213 219) !important;
    font-size: 1rem !important;
}

.check-documents-actions {
    width: 100% !important;
    justify-content: flex-end !important;
    padding: 0 !important;
    margin: 0 !important;
    gap: 0.5em !important;
}

.check-documents-button {
    background-color: transparent !important;
    color: #df9133 !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    padding: 5px 12px !important;
    box-shadow: none !important;
}

.check-documents-button:focus {
    box-shadow: none !important;
}
