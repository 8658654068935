.production .fc-timeGridDay-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.production .fc-timeGridDay-button:hover{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}

.production .fc-timeGridWeek-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.production .fc-timeGridWeek-button:hover{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}

.production .fc-dayGridMonth-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.production .fc-dayGridMonth-button:hover{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}

.production .fc-today-button {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
    color: #7B8FA1 !important;
    font-weight: bold !important;
}

.production .fc-today-button:hover {
    background-color: #F0E68C !important;
    border: 1px solid #F0E68C !important;
}

.production .fc-today-button:disabled {
    font-weight: normal !important;
}

.production .fc-next-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.production .fc-next-button:hover{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}

.production .fc-prev-button{
    background-color: #7B8FA1 !important;
    border: #7B8FA1 !important;
}

.production .fc-prev-button:hover{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}

.production .fc-button-active{
    background-color: #1ab800 !important;
    border: #1ab800 !important;
}
