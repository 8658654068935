@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
}
::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar:vertical {
    width: 6px;
}
::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(216, 216, 216);
}
::-webkit-scrollbar-track:vertical {
    background-color: transparent;
}
